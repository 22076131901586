@import "colors";
@import "style";
// html {
//   zoom: 0.9;
//   -ms-zoom: 0.9;
//   -webkit-zoom: 0.9;
//   -moz-transform: scale(0.9, 0.9);
//   -moz-transform-origin: left center;
// }
// html {
//   font-size: 16px;
// }

// @media only screen and (min-width: 720px) {
//   html {
//       font-size: 18px;
//   }
// }

// @media only screen and (min-width: 960px) {
//   html {
//       font-size: 20px;
//   }
// }

// @media only screen and (min-width: 1240px) {
//   html {
//       font-size: 24px;
//   }
// }

body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
section,
span,
a,
p {
  box-sizing: border-box;
}

.flex {
  display: flex;
}

//to keep the scrollbar alawys visible. prevent jerk issues while changing pages
body {
  min-height: 100.06vh;
}

.App {
  // background: #f9fbfd;
  background: $primary;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

// for the body scroll. for customisation use specific css
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  // background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 15px;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.padding-15 {
  padding: 15px;
}

.padding-30 {
  padding: 30px;
}

.sp-bw {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-self-center {
  align-self: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.text-align-right {
  text-align: right;
}

button,
.btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 30px;
  border-radius: 5px;
  font-size: $font14;
  font-weight: 500;
  white-space: nowrap;
}

.btn-disabled {
  background-color: $disabled;
  border: 1px solid $disabled;
  color: #8e9093;
  width: 250px;
  align-content: center;
  text-align: center;
  cursor: default;
  pointer-events: none;
}

.btn-primary {
  background-color: $primary;
  color: $white;
  box-shadow: $boxShadow;
  border: 1px solid $primary;
  width: 250px;
  align-content: center;
  text-align: center;
}

.btn-secondary {
  background-color: $secondary;
  color: $primary;
  box-shadow: $boxShadow;
  border: 1px solid $primary;
  width: 250px;

  &:hover {
    background-color: #404e5e;
    border: none;
    color: white;
    transition: 0.4s all ease;
    transform: translate(0, -2px);
  }
}
//#446EB8 btn
.btn-primary-blue {
  background-color: $cobalt;
  color: #ffffff;
  align-content: center;
  text-align: center;
  border: none;
  border-radius: 4px;
  width: 250px;

  &:hover {
    background-color: #345fab;
    color: #fff;
    border: none;
  }
}
.btn-stepper-primary {
  background-color: $tangerine;
  color: #ffffff;
  align-content: center;
  text-align: center;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  width: 90px !important;
  position: relative;
  bottom: 1em;
  margin-top: 25px;

  // &:hover {
  //   //background-color: #345FAB;
  //   color: #fff;
  //   border: none;
  //   transition: 0.4s all ease;
  //   transform: translate(0, -2px);
  // }
}
.btn-stepper-secondary {
  background-color: $grey;
  color: $tangerine;
  align-content: center;
  text-align: center;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  // font-family: "open-sans";

  // &:hover {
  //   //background-color: #345FAB;
  //   //color:#fff;
  //   border: none;
  //   transition: 0.4s all ease;
  //   transform: translate(0, -2px);
  // }
}
.btn-secondary-blue {
  border: 1px solid #446eb8;
  background-color: transparent;
  color: $cobalt;
  align-content: center;
  text-align: center;
  border-radius: 4px;
  width: 250px;

  &:hover {
    background-color: transparent;
    color: #345fab;
    background-color: 1px solid #345fab;
  }
}
// .btn-stepper-red{
//   background-color: red !important;
// }
.nxtbtn-disabled {
  background-color: #a1b6db !important;
  //color:#c4c4c4 !important;
}

// underlined button
.btn-underline-button {
  margin-top: 10px;
  width: 160px;
  display: flex;
  color: #446eb8;
  position: relative;
  right: 30px;
  bottom: 10px;
  text-decoration: underline;
}
.content-wrapper {
  width: 75%;
}

.nav-menu {
  margin: 0px 0px 0px 20px;
  width: 80px;
  position: fixed;
  z-index: 1;
  // top: 13vh;
  left: 0;
  transition: 0.5s ease;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: $boxShadow;
  border-radius: 30px;
  background-color: $secondary;
  // max-height: calc(100vh - 2rem);
  overflow: auto;
  min-height: 480px;

  &.collapse {
    a {
      padding: 1px;
    }
  }

  &.expand {
    width: 250px;
    padding: 0px 0px 0.625rem 1.2rem;
    height: 480px;

    a {
      align-items: center;
      margin: 0.0625rem 0px;
      width: 91.5%;

      .nav-icons {
        margin-right: 8%;
      }
    }

    .dashboard-text {
      // padding: 20px;
      span:nth-child(2) {
        margin-right: auto;
      }

      .nav-icons {
        margin-right: 8%;
      }

      .go-back-icon {
        // margin-left: 40%;
        // margin-right: 10px;
      }
    }
  }

  a {
    flex: 1;
    color: $primary;
    text-decoration: none;
    padding: 3px;
    width: 100%;
    // margin: 0 10px;
    // text-align: left;
    // justify-content: flex-start;
    margin: 0.0625rem 0px;
    opacity: 1;
    display: flex;
  }

  .dashboard-text {
    font-weight: 600;
    color: $primary;
    text-decoration: none;
    // padding: 20px 5px;
    width: 100%;
    margin: 5px;
    // text-align: left;
    // justify-content: flex-start;
    display: flex;
    font-size: 1rem;
    justify-content: center;
  }

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    outline: 1px solid #c4c4c4;
  }

  .nav-go-back-div {
    padding: 8px;
    padding: 8px;
    padding-left: 0px;
    padding-right: 0px;

    img {
      cursor: pointer;
    }
  }

  .nav-accessibility {
    width: 100%;
    //display: flex;
   // align-items: center;
   // justify-content: end;
    text-align: end;
    padding-right: 10px;
  }

  .nav-access-column {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
  }

  .nav-items-icon {
    width: 100%;
    display: flex;
    align-items: center;

    a .icon {
      background-size: 20px 20px;
    }

    a .nav-items {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #000000;
      width: 150px;
    }

    &:hover {
      .nav-icons {
        filter: invert(70%) sepia(52%) saturate(1570%) hue-rotate(354deg)
          brightness(95%) contrast(89%);
      }

      .nav-items-arrow {
        filter: invert(70%) sepia(52%) saturate(1570%) hue-rotate(354deg)
          brightness(95%) contrast(89%);
      }

      a .nav-items {
        color: $tangerine;
      }

      & a:not(.is-active) {
        border-right: 4px solid $tangerine;
      }
    }

    & .is-active {
      .nav-items {
        color: $tangerine;
        font-weight: 600;
      }

      .nav-icons {
        filter: invert(58%) sepia(52%) saturate(1570%) hue-rotate(354deg)
          brightness(95%) contrast(89%);
      }

      .nav-items-arrow {
        filter: invert(58%) sepia(52%) saturate(1570%) hue-rotate(354deg)
          brightness(95%) contrast(89%);
      }

      border-right: 4px solid $tangerine;
    }
  }

  .collapse-nav-icon {
    width: 100%;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      justify-content: center;

      & .icon {
        background-size: 24px 24px;
      }
    }

    a .nav-items {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #000000;
    }

    .nav-icons {
      filter: invert(58%) sepia(52%) saturate(1570%) hue-rotate(354deg)
        brightness(95%) contrast(89%);
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: $tangerine;

      .nav-icons {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(179deg)
          brightness(103%) contrast(103%);
        -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(179deg)
          brightness(103%) contrast(103%);
      }

      a .nav-items {
        color: $tangerine;
      }

      & a:not(.is-active) {
        background-color: $tangerine;
        .nav-icons {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(179deg)
            brightness(103%) contrast(103%);
        }
      }
    }

    & .is-active {
      .nav-items {
        color: $tangerine;
        font-weight: 600;
      }

      .nav-icons {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(179deg)
          brightness(103%) contrast(103%);
        -webkit-filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(179deg)
          brightness(103%) contrast(103%);
      }

      background-color: $tangerine;
    }
  }
}

.outlet {
  border-radius: 0px 30px 30px 30px;
  margin: 0px 0px 20px 20px;

  .page-wrapper,
  .maintenance-log-container,
  .rca__container,
  .driver-behaviour-summary-wrapper {
    border-radius: 30px 30px 30px 30px;
    // background-color: #EEEEEE;
  }
}

.tabs-container-box {
  .tab-outlet {
    background-color: #eeeeee;
    border-radius: 0px 30px 30px 30px;
    padding: 10px 0px;
    // min-height: 680px;

    &.grey {
      background-color: #f7f8fa;
    }
  }
  .tab-outlet--notabs {
    border-radius: 30px 30px 30px 30px;
    padding: 0px;
  }

  .MuiTabs-indicator {
    display: none;
  }

  .tab-link {
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    margin-right: 2px;
    text-transform: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #041f3e;
  }

  .Mui-selected {
    background-color: $tangerine;
    color: #ffffff !important;
  }
  .tabheight--hidden {
    min-height: 0px;
  }
}

.nav-icons {
  margin-right: 0px;

  &.hamburger-icon {
    background: url("../assets/hamburger.svg") 100% 100% no-repeat;
  }

  &.over-view {
    background: url("../assets/overView.svg") 100% 100% no-repeat;
  }

  &.usage-profile {
    background: url("../assets/usageProfile.svg") 100% 100% no-repeat;
  }

  &.health-check {
    background: url("../assets/vehicleHealthCheck-27-3.svg") 100% 100% no-repeat;
  }

  &.remote-monitoring {
    background: url("../assets/remoteMonitoring.svg") 100% 100% no-repeat;
  }

  &.fault-trend {
    background: url("../assets/faultTrend.svg") 100% 100% no-repeat;
  }

  &.perspective-analytics {
    background: url("../assets/prescriptiveAnalytics.svg") 100% 100% no-repeat;
  }

  &.maintainance-overview {
    background: url("../assets/maintainance.svg") 100% 100% no-repeat;
  }

  &.maintainance-logs {
    background: url("../assets/logs.svg") 100% 100% no-repeat;
  }

  &.warranty-management {
    background: url("../assets/warrantyManagement-27-3.svg") 100% 100% no-repeat;
  }

  &.configure-features {
    background: url("../assets/configure-features.svg") 100% 100% no-repeat;
  }

  &.save-filter {
    background: url("../assets/save-filter.svg") 100% 100% no-repeat;
  }

  &.vehcile-population-dashboard {
    background: url("../assets/vehiclepopulation-dashboard.svg") 100% 100%
      no-repeat;
  }

  &.generated-dashboard {
    background: url("../assets/generated-dashboard-icon.svg") 100% 100%
      no-repeat;
  }
}

.nav-items {
  // color: #353536;
  // font-size: 0.875rem;
  white-space: nowrap;
}

.inline-block {
  display: inline-block;
}

.admin-nav-content.expand > a {
  max-height: 8%;
}

.admin-nav-content-collapse > a {
  max-height: 8%;
}

@media only screen and (max-width: 767.5px) {
  .btn {
    padding: 5px 10px;
    font-size: $font12;
  }
}

@media screen and (min-width: 1100px) {
  .nav-menu a {
    align-items: center;
  }

  .btn-primary {
    font-size: 14px;
    width: 220px;
  }

  .btn-secondary {
    font-size: 14px;
    width: 220px;
  }
}

@media screen and (min-width: 1300px) {
  .nav-menu {
    &.expand {
      padding-bottom: 15px;
      overflow: hidden;
    }

    a {
      // padding: 12px 1.25rem;
      align-items: center;
    }
  }

  .nav-items {
    font-size: 14px;
  }

  .dashboard-text {
    font-size: 18px;
  }
}

.date-picker {
  height: 45px;
  //background: #ffffff;
  //box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: flex;
  margin: 10px 0px 6px 30px;

  input {
    width: 90px;
    padding: 5px;
    margin: 7px;
    border: 0;
  }

  p {
    font-size: 15px;
  }
}

.datepicker-input-field {
  height: 40px;
}

.datepicker-input-field:hover {
  color: white;
}

.input-value-top-10-dtc {
  font-size: 12px !important;
}

@media screen and (min-width: 1400px) {
  .nav-items {
    font-size: 16px;
  }

  .nav-menu .dashboard-text {
    font-size: 16px;
  }

  .btn-primary {
    font-size: 16px;
    width: 250px;
  }

  .btn-secondary {
    font-size: 16px;
    width: 250px;
  }
}

@media screen and (min-width: 1700px) {
  .nav-menu {
    &.expand {
      padding-bottom: 15px;
    }

    a {
      // padding: 12px 1.25rem;
      align-items: center;
    }
  }

  .nav-items {
    font-size: 18px;
  }

  .nav-menu .dashboard-text {
    font-size: 18px;
  }
}

// @media screen and (max-height: 640px) {
//   .nav-menu {
//     &.expand {
//       padding-bottom: 0px;
//     }

//     a {
//       padding: 3px;
//       align-items: center;
//     }
//   }

//   .nav-items {
//     font-size: 14px;
//   }

//   .dashboard-text {
//     font-size: 16px;
//   }
// }

.apiError {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;
  flex-direction: column;
  height: 600px;
  width: 100%;
  padding-block: 60px;
  padding-top: 0px;
  padding-bottom: 0px;
  position: relative;
  bottom: 35px;
  margin-top: 0px;
  span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-family: "Open Sans", sans-serif !important;
    font-weight: 400;
    font-size: 12px;
  }
  span::before {
    content: " ";
    border-top: 2px solid orange;
    width: 50px;
  }
  span::after {
    content: " ";
    border-top: 2px solid orange;
    width: 50px;
  }
  h2 {
    margin: 10px;
    color: #0147ab;
    font-weight: 20px;
  }
}

.nav-icons:hover {
  filter: invert(58%) sepia(52%) saturate(1570%) hue-rotate(354deg)
    brightness(95%) contrast(89%);
}

//#446EB8 btn
.btn-primary-blue {
  background-color: $cobalt;
  color: #ffffff;
  align-content: center;
  text-align: center;
  border: none;
  border-radius: 4px;
  width: auto;

  &:hover {
    background-color: #1f4d9f;
    color: #fff;
    border: none;
  }

  &.disabled {
    background-color: #849ecc;
  }
}

.btn-secondary-blue {
  border: 1px solid #446eb8;
  background-color: transparent;
  color: $cobalt;
  align-content: center;
  text-align: center;
  border-radius: 4px;
  width: auto;

  &.position {
    padding: 10px;
    font-size: 14px;
    position: relative;
    bottom: 10px;
    margin-left: 10px;
  }

  &:hover {
    border: 1.1px solid #0059ff;
    color: #0059fd;
  }
}
.nav-items-arrow {
  display: flex;
  transform: rotate(270deg);
  width: 10px;
  height: 7px;
  &.down {
    transform: rotate(0deg);
  }
}

.nav-child-menu {
  min-height: 10px;
  max-height: 90px;
  overflow: hidden;
}

.nav-child-full {
  min-height: 10px;
  overflow: hidden;
}
.more-nav-menu {
  font-size: 14px;
  display: flex;
  margin-left: 16px;
  align-items: end;
  gap: 8px;
  cursor: pointer;

  &.collapse {
    justify-content: center;
    margin: 7px 20px 7px 32px;
  }

  .up-arrow {
    transform: rotate(180deg);
  }
  img {
    width: 15px;
    height: 15px;
  }
  &:hover {
    filter: invert(25%) sepia(188%) saturate(1644%) hue-rotate(554deg)
      brightness(78%) contrast(135%);
  }
}

.nav-child {
  // display: flex;
  width: 221px;
  overflow: hidden;
  margin-left: 10px;

  a {
    width: 100% !important;
    .icon {
      margin-right: 3% !important;
    }
  }

  .nav-children {
    margin: 10px;
  }

  & .is-active {
    .nav-items {
      color: #3856c2;
      // font-weight: 400;
    }

    .nav-icons {
      filter: invert(25%) sepia(188%) saturate(1644%) hue-rotate(554deg)
        brightness(78%) contrast(135%);
    }
  }

  &:hover {
    .nav-icons {
      filter: invert(25%) sepia(188%) saturate(1644%) hue-rotate(554deg)
        brightness(78%) contrast(135%);
    }

    a .nav-items {
      color: #3856c2;
    }
  }
}
.collapse-nav-child {
  margin-left: 27px;
  width: 30px;

  & .is-active {
    .nav-items {
      color: #3856c2;
      // font-weight: 400;
    }

    .nav-icons {
      filter: invert(25%) sepia(188%) saturate(1644%) hue-rotate(554deg)
        brightness(78%) contrast(135%);
    }
  }

  &:hover {
    .nav-icons {
      filter: invert(25%) sepia(188%) saturate(1644%) hue-rotate(554deg)
        brightness(78%) contrast(135%);
    }

    a .nav-items {
      color: #3856c2;
    }
  }
}

.nav-items {
  font-size: 14px !important;
  font-weight: 400;
}
