@import "../../scss/colors";

.vehicle-population-container {
  background-color: #eeeeee;
  border-radius: 30px 0px 0px 30px;
  width: 98%;
  min-height: 650px;
  padding: 10px;

  .top-button-container {
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    height: 40px;

    p {
      background-color: #446eb8;
      height: 100%;
      margin-top: 0px;
      color: white;
      padding: 0px 5px 0px 5px;
      font-size: 13px;
      border-radius: 5px;
    }

    img {
      position: relative;
      top: 8px;
      cursor: pointer;
    }
  }
}

.Top-container {
  display: grid;
  padding-top: 10px;
  width: 100%;
  float: left;
  grid-template-columns: 19% 19% 19% 19% 19%;
  // grid-template-rows: 150px 5% 5% 5% 5% 5%;
  grid-gap: 8px;
  position: relative;
  // right: 30px;
}

.vehicle-dashboard-middle-container {
  // width: 544%;
  // height: 415px;
  border: 1px solid black;
  margin-top: 20px;
  border-radius: 5px;
  background-color: #ffffff;

  .highcharts-axis-labels {
    font-weight: 800;
  }

  .y-axis-legend-label {
    text-align: right;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    max-width: 180px;
  }

  .vehicle-population-highchart {
    width: 90%;
    position: relative;
    right: 30px;
  }

  .legend-wrapper {
    margin-top: 20px;
    justify-content: space-between;
  }

  .select-container-all-compare {
    margin-left: 40px;

    .tab {
      width: 60px;
    }

    .select-options-container {
      right: 28px !important;
    }

    .select-option {
      right: 85px !important;
    }

    .option-value {
      padding: 8px 10px;
    }
  }
}

.select-container-metrics-compare {
  display: flex;
  justify-content: end;
  margin-right: 20px;

  .tab {
    width: 100px;
    right: 0px;
    margin-left: 40px !important;
  }

  .select-option {
    left: 44px;
    right: 13px;
  }

  .option-value {
    padding: 8px 10px;
  }

  .tab2 {
    width: 150px;
    right: 0px;
    margin-left: 40px !important;
  }

  // .compare-rul-top-10-dtc {
  //   margin-right: 10px;
  //   width:150px
  // }
}

.showdetails {
  width: 15%;
  position: relative;
  left: 50%;
  bottom: 20px;
  border: 1px solid #446eb8;
  color: #446eb8;
  background-color: #fff;
}

.showdetails:hover {
  color: white;
  background-color: #446eb8;
}

.showdetailsIndividual {
  left: 80%;
}

.Report-Diagnostic-text {
  padding: 5px;
  width: 20%;
  background-color: #fcf3e7;
  box-shadow: 0px 1px 4px rgb(100 116 139 / 12%);
  border-radius: 5px 5px 5px 5px;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 10px;
  margin-bottom: 10px;
  color: #a16002;
}

.white-container-tab {
  height: 100px;
  // border: 1px solid black;
  background-color: $tropicalblue;
  border-radius: 10px;
  text-decoration: none;
  color: #000000;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

  .grid-item {
    background-color: $niceblue;
  }

  .img-icon {
    position: relative;
    bottom: 15px;
    height: 50%;
    left: 20px;
  }
}

.orange-container-tab:hover {
  height: 100px;
  // border: 1px solid black;
  background-color: $tropicalblue;
  border-radius: 10px;
  text-decoration: none;
  color: #000000;

  .grid-item {
    background-color: $niceblue;
  }
}

.box-container {
  display: grid;
  grid-template-columns: repeat(6, 6px);
  padding: 0px 5px 0px 8px;
  column-gap: 10%;
  margin-top: 10px;
  height: 25px;

  .grid-item {
    padding: 0px;
    width: 7px;
    border-radius: 2px 2px 0px 0px;
  }

  .tab1 {
    margin-top: 0px;
  }

  .tab2 {
    margin-top: 3px;
  }

  .tab3 {
    margin-top: 6px;
  }

  .tab4 {
    margin-top: 9px;
  }

  .tab5 {
    margin-top: 12px;
  }

  .tab6 {
    margin-top: 16px;
  }
}

.right-vehicle-container {
  float: right;
  width: 23%;
  margin-top: 11px;
  display: grid;
  grid-gap: 13px 15px;
  grid-template-columns: auto auto;
  position: relative;
  // left: -10px;

  .select-conatiner {
    border-radius: 7px;
    text-align: center;
    background-color: white;
    color: black;
    text-decoration: none;
    height: 55px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

    .vehicle-icon2 {
      fill: red;
    }
  }

  .select-conatiner-active {
    border-radius: 7px;
    text-align: center;
    background-color: $tropicalblue;
    color: #000000;
    text-decoration: none;
    height: 55px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

    .vehicle-icon2 {
      fill: red;
    }

    .image-vehicle {
      // filter: invert(100%);
    }
  }

  .select-conatiner:hover {
    background-color: $tropicalblue;
    color: #000000;

    .image-vehicle {
      // filter: invert(100%);
    }
  }
}

.orange-container-tab {
  height: 100px;
  border-radius: 10px;
  // width: 100%;
  text-decoration: none;
  color: black;
  background-color: white;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));

  .grid-item {
    background-color: $niceblue;
  }

  .img-icon {
    position: relative;
    bottom: 15px;
    height: 50%;
    left: 20px;
  }
}

.vehicle-population-form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  .select-container {
    width: 65%;
    max-width: 65%;
  }
}

.data-picker-component {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    // background: url(./assets/Altitude-image.svg) no-repeat;
    width: 20px;
    height: 20px;
  }

  // input[type="date"]:before{
  //   background-image: url(./assets/CalenderIcon.svg);
  // }

  label {
    padding-top: 10px;
  }

  input {
    margin-left: 40px;
    font-size: larger;
    border-width: thin;
    border: 1px solid #bfbcbc;
    border-radius: 5px;
    padding: 5px;
    //  background-image: none;
  }

  //   input[type="date"]::-webkit-calendar-picker-indicator {
  //     background: transparent;
  //     bottom: 0;
  //     color: transparent;
  //     cursor: pointer;
  //     height: auto;
  //     left: 0;
  //     position: absolute;
  //     right: 0;
  //     top: 0;
  //     width: auto;
  // }
}

.generate-vehicle-population {
  color: #000;
  background: #edf1f8;
  width: 36%;
  font-size: 14px;
  padding: 5px;
  border-radius: 5px 5px 0px 0px;
  /* margin: 10px; */
  margin-bottom: 30px;
  position: relative;
  top: 10px;
}

.metric-box {
  display: flex;
  //justify-content: right;
  position: relative;
  top: 13px;
  left: 250px;
  width: 50%;
  height: 9%;
}

.input-box {
  border: 1px solid black;
}

.Dtc-value-input {
  margin-right: 10px;
  width: 110px;
  font-size: 12px;
  position: relative;
}

.top-10-dtc-date {
  width: 120px;
  margin-right: 10px !important;
}

.graph-name {
  text-align: center;
  position: relative;
  font-size: 14px;
  // top: 10px;
  font-weight: 600;
  margin-bottom: 10px;
}

.outer-box {
  top: 60px;
}

.feedback {
  height: 300px;
  bottom: 0px;
}

.firstTable {
  // box-shadow: 0px 2px 4px -2px rgba(24, 39, 75, 0.12),
  //   0px 4px 4px -2px rgba(24, 39, 75, 0.08);
}

.firstTable table {
  width: 239px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 0px;
  margin-top: 3rem;
}

.firstTable thead tr {
  box-shadow: none !important;
  /* text-align: center; */
}

/* .firstTables th  {
    
} */
.mainTable-container {
  display: flex;
  font-size: 14px;
  justify-content: center;
}

.firstTable th {
  background: #fff;
  border: none;
  font-size: 14px;
  border-right: none !important;
  box-shadow: none;
  text-align: center;
  font-weight: 400;
}

.firstTable th tr {
  border: none;
  background: #fff;
}

.firstTable tbody tr {
  border: none;
}

.firstTable table td {
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-top: none;
}

.firstTable table tbody td:nth-child(2n) {
  padding: 0px;
}

.firstTable th {
  background: #fff;
  border: none;
  font-size: 14px;
  border-right: none !important;
  box-shadow: none;
  text-align: center;
  font-weight: 400;
}

.firstTable {
  .bmt-text {
    display: flex;
    justify-content: space-around;
    position: relative;
    bottom: 61px;
    height: 37px;
    margin: 6px;
    background-color: $cobalt;
    color: white;
    border-radius: 5px;

    p:first-child {
      margin-top: 8px;
      position: relative;
      right: 20px;
    }

    p:nth-child(2) {
      margin-top: 8px;
      position: relative;
      left: 20px;
    }
  }
}

.mainTable-container {
  display: flex;
}

.secondTable table {
  margin-top: 1.07rem;
  /* border-radius:5px !important; */
  width: 142px;
  text-align: center;
  padding: 30px 5px;
}

.secondTable th {
  text-align: center;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  border-radius: none !important;
}

.secondTable tbody tr td {
  padding-top: 7px;
  padding-bottom: 8px;
  text-align: center;
}

.thirdTable table {
  margin-top: 1.07rem;
  width: 142px;
  text-align: center;
  padding: 30px 5px;
}

.thirdTable th {
  text-align: center;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  border-radius: none !important;
}

.thirdTable tbody tr td {
  padding-top: 7px;
  padding-bottom: 8px;
  text-align: center;
}

.fourthTable table {
  width: 276px;
  margin-top: 1.07rem;
  padding: 30px 5px;
}

.fourthTable tbody tr td {
  padding-top: 6.7px;
}

.fourthTable th {
  text-align: center;
  background: #fff;
  font-weight: 400;
  font-size: 14px;
  border-radius: none !important;
}

table th:nth-last-child(1) {
  border-radius: 0px 0px 0px 0px !important;
}

.btmText {
  display: flex;
  justify-content: space-between;
  /* right: 10px; */
  position: relative;
  left: 9px;
  bottom: 27px;
  margin-top: 10px;
  width: 555px;
  line-height: 1.5px;
  /* height: 43px; */
  background: #f9f9f9;
  margin-left: 6px;
  margin-bottom: 5px;
}

.firstTable tbody tr:last-child td {
  border-bottom: none !important;
}

p.equalsTo1 {
  position: relative;
  left: 8px;
}

// .firstTable th:nth-last-child(1){
//   border-radius:0px !important;
// }
// .firstTable thead th {
//    background-color: transparent !important;
//    border-radius: 8px 8px 0px 0px;
// }
p.formula1 {
  position: relative;
  right: 50px;
}

p.formula2 {
  position: relative;
  right: 80px;
}

.thirdTable th,
.secondTable th,
.fourthTable th {
  background: #f9f9f9;
}

.thirdTable tbody,
.secondTable tbody,
.fourthTable th {
  background: #f9f9f9;
}

.firstTable {
  padding: 0px 5px;
}

.firstTable {
  padding: 0px 5px;
}

.secondTable th {
  border: 0px;
  border-right: 0px !important;
}

.secondTable tbody td {
  border: 0px !important;
}

.thirdTable th {
  border: 0px;
  border-right: 0px !important;
}

.thirdTable tbody td {
  border: 0px !important;
}

.fourthTable th {
  border: 0px;
  border-right: 0px !important;
}

.fourthTable tbody td {
  border: 0px !important;
  background: #f9f9f9;
}

.mapping {
  position: relative;
  top: 140px;
  left: 30px;
  width: 25%;
  height: 260px;
  font-size: 14px;
  font-weight: 500;
}

.geo-map-occurance-container {
  display: flex;
  margin-bottom: 5px;
}

.geo-map-occurance-color {
  border: 1px solid black;
  height: 15px;
  width: 30px;
  margin: 0px;
  padding-left: 0px;
  margin-left: 0px;
}

.geo-map-occurance-data {
  margin: 0px;
  padding-top: 0px;
  margin-left: 10px;
  position: relative;
  bottom: 2px;
  font-size: 12px;
}

.geo-map-occurance-dtc-code {
  margin: 8px;
  font-size: 12px;
}

.inp {
  margin-right: 26em;
  margin-top: 5px;
}

input.selectedVals {
  width: 25em;
  height: 2.4em;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
}

.color-wrapper {
  width: 200px;
  justify-content: end;
  display: flex;
  padding-right: 15px;

  img {
    height: 16px;
    margin-right: 7px;
    position: relative;
    bottom: 3px;
  }
}

.display-selected-data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  width: 175px;
  border-radius: 6px;
  padding: 4px;
  background: rgba(5, 116, 166, 0.2);
  border: 0.3px solid rgba(4, 31, 62, 0.8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;

  &.grey {
    background: #d9d9d9;
  }
}

.display-selected-data-title {
  position: relative;
  bottom: 37px;
  left: 5px;
  width: 70px;
  height: 16px;
  background-color: #f7f8fa;
  border: 0.1px solid #dbdbdb;
  box-shadow: 12px 12px 24px -2px rgba(24, 39, 75, 0.12);
  border-radius: 3px;
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #343436;
  padding: 0px 3px;
}

.top-button-container-comparing {
  display: flex;

  .inp {
    margin-right: 10px;
  }
}

.compare-dropdown {
  width: 236px;
  border: 1px solid;
  position: absolute;
  top: 203px;
  right: 73px;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  height: 263px;
  overflow-y: auto;

  .compare-dropdown-search {
    margin: 5px;

    .search_image {
      width: 13px;
      height: 15px;
      top: 3px !important;
      left: 30px;
    }

    .search_field {
      width: 200px;
      height: 31px;
      padding: 12px 0px 12px 34px;
      border: 1px solid #c4c4c4;
      border-radius: 30px;
      font-size: 14px;
      color: #fff;
      background-color: #000;

      input {
        display: flex;
      }
    }
  }

  .compare-dropdown-values {
    color: #fff;
    cursor: pointer;

    .compare-dropdown-value {
      border-bottom: 1px solid;
      text-align: start;
      padding: 8px 0px 8px 32px;
    }
  }
}

.comparison-geomap {
  p {
    position: relative;
    text-align: center;
    top: 20px;
    font-weight: 500;
    /* font-family: san; */
    font-size: 15px;
  }
}

.on-off-button {
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: end;
  position: relative;
  top: 30px;
  right: 20px;
}

.comparecard-text {
  position: relative;
  left: 600px;
  bottom: 20px;
  width: 205px;
}

.right-vehicle-container-icon {
  display: grid;
  float: right;
  width: 8%;
  position: relative;
  left: 29px;
  bottom: 67px;
  border-radius: 28px;
  background-color: #ffffff;
  padding-left: 34px;
  padding-top: 110px;
}

.compare-button {
  cursor: pointer;

  &.disabled {
    background-color: #6188ca !important;
    cursor: default;
  }
}

.compare-with-vin-button {
  cursor: pointer;

  &.disabled {
    background-color: #6188ca !important;
    cursor: default;
  }
}

.compare-dropdown::-webkit-scrollbar {
  width: 5px;
}

.b-c--forcwv1 .grid-item:nth-child(1),
.b-c--forcwv1 .grid-item:nth-child(2),
.b-c--forcwv1 .grid-item:nth-child(5) {
  background-color: #000000;
}

.b-c--forcwv2 .grid-item:nth-child(1) {
  background-color: #000000;
}

.b-c--forcwv3 .grid-item:nth-child(3),
.b-c--forcwv3 .grid-item:nth-child(4) {
  background-color: #000000;
}

.b-c--forcwv .grid-item:nth-child(4) {
  background-color: #000000;
}

.box-container-cwf {
  display: grid;
  grid-template-columns: repeat(14, 0px);
  padding: 0px 5px 0px 8px;
  column-gap: 7%;
  margin-bottom: 10px;
  height: 35px;

  .grid-item {
    padding: 0px;
    width: 5px;
    border-radius: 6px 6px 0px 0px;
  }

  .tab11 {
    margin-top: 35px;
  }

  .tab1 {
    margin-top: 0px;
  }

  .tab2 {
    margin-top: 3px;
  }

  .tab3 {
    margin-top: 6px;
  }

  .tab4 {
    margin-top: 9px;
  }

  .tab5 {
    margin-top: 12px;
  }

  .tab6 {
    margin-top: 16px;
  }

  .tab7 {
    margin-top: 18px;
  }

  .tab8 {
    margin-top: 20px;
  }

  .tab9 {
    margin-top: 22px;
  }

  .tab10 {
    margin-top: 24px;
  }
}

.b-c--forcwf1 .grid-item:nth-child(1),
.b-c--forcwf1 .grid-item:nth-child(3),
.b-c--forcwf1 .grid-item:nth-child(7),
.b-c--forcwf1 .grid-item:nth-child(9),
.b-c--forcwf1 .grid-item:nth-child(11) {
  background-color: #000000;
}

.b-c--forcwf2 .grid-item:nth-child(1),
.b-c--forcwf2 .grid-item:nth-child(3) {
  background-color: #000000;
}

.b-c--forcwf3 .grid-item:nth-child(3),
.b-c--forcwf3 .grid-item:nth-child(4) {
  background-color: #000000;
}

.b-c--forcwf .grid-item:nth-child(1),
.b-c--forcwf .grid-item:nth-child(3),
.b-c--forcwf .grid-item:nth-child(5),
.b-c--forcwf .grid-item:nth-child(7),
.b-c--forcwf .grid-item:nth-child(9) {
  background-color: #000000;
}

.b-c--forcwf4 .grid-item:nth-child(1),
.b-c--forcwf4 .grid-item:nth-child(3),
.b-c--forcwf4 .grid-item:nth-child(5),
.b-c--forcwf4 .grid-item:nth-child(7) {
  background-color: #000000;
}

.b-c--forcwf5 .grid-item:nth-child(1),
.b-c--forcwf5 .grid-item:nth-child(4),
.b-c--forcwf5 .grid-item:nth-child(6),
.b-c--forcwf5 .grid-item:nth-child(8),
.b-c--forcwf5 .grid-item:nth-child(10),
.b-c--forcwf5 .grid-item:nth-child(13) {
  background-color: #000000;
}

.usageprofile--compare {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  span {
    font-size: 14px;
    line-height: 26px;
  }
}

// @media screen and (min-width: 1400px) and (max-width: 1500px) {
//   .btmText {
//     left: 350px !important;
//   }
// }

// @media screen and (min-width: 1500px) and (max-width: 1600px) {
//   .btmText {
//     left: 387px !important;
//   }
// }

// @media screen and (min-width: 1600px) and (max-width: 1700px) {
//   .btmText {
//     left: 420px !important;
//   }
// }

// @media screen and (min-width: 1700px) and (max-width: 1800px) {
//   .btmText {
//     left: 454px !important;
//   }
// }

// @media screen and (min-width: 1800px) and (max-width: 1900px) {
//   .btmText {
//     left: 485px !important;
//   }
// }

// @media screen and (min-width: 1900px) and (max-width: 2000px) {
//   .btmText {
//     left: 524px !important;
//   }
// }
